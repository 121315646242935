// legal template page for Terms, Privacy and Cookies (based on about page)
import React from "react"
import Reactmarkdown from "react-markdown"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO }  from "../components/seo"
import Headings from "../components/sg-headings"
import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiLegal.headline 
  const description = data.strapiGlobal.siteDescription 
  return (
    <SEO title={title} description={description} />
  )
}


export default function LegalPage ({ data }) {

const legal = data.strapiLegal  

// Custom header required (not from headings) and built a standard weboage for about/contact
// Change image to a legal one that works for all.

const background = getImage(data.bgFile)
const headline = legal.headline
const modifiedDate = `Last Updated `+ legal.modifiedDate
const bodyText = legal.body.data.body
   
return (
        <Layout>
          <div className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={background} 
              alt="Legal background image jackson.fi" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <Headings title={headline} description={modifiedDate} />
                </div>  
          </div>
            <div className="container px-6 pb-6 pt-6 space-y-4"><Reactmarkdown>{bodyText}</Reactmarkdown></div>
        </Layout>
      )
    }

// query from Strapi - this needs to cycle based on slug
// Url should be legal/$slug from strapi


export const query = graphql`
query ($slug: String) {
  strapiLegal (slug: {eq: $slug}) {
    headline
    modifiedDate
    body {
      data {
        body
      }
    }
  }
  strapiGlobal {
    siteDescription
  }
  bgFile: file(relativePath: {eq: "images/legal.jpg"}) {
    childImageSharp {
      gatsbyImageData
    }
  }
}`